@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');



@layer utilities{
    .btn{
        @apply text-white inline-block px-5 py-2 rounded-lg;
    }
    .btn-light{
        @apply bg-orange-400 border border-orange-400 hover:bg-orange-300;
    }
    .btn-enroll{
        @apply lg:text-center lg:text-2xl;
    }
    .btn-disabled {
        pointer-events: none;
        color: gray;
        text-decoration: none;
        @apply bg-gray-400 border border-gray-400;
    }


    .course{
        @apply flex max-lg:flex-col justify-around max-lg:items-center my-4;
    }
    .course-image-span{
        @apply bg-red-700 text-white text-center p-1 rounded-md absolute top-2 left-2;
    }
    .course-box{
        @apply text-seoem-100 flex-auto flex-col lg:ml-4 max-lg:text-center my-4;
    }
    .course-title{
        @apply text-lg font-bold;    
    }
    .course-date{
        @apply text-sm font-thin opacity-80;
    }
    .course-description{
        @apply text-wrap opacity-80 lg:w-[635px];
    }
    .course-instructor{
        @apply text-sm font-thin opacity-80;
    }
    .image-content-box{
        @apply w-2/3 mt-8 mb-12 mx-auto;
    }
    .image-content{
        @apply rounded-lg m-auto;
    }
}